<template>
    <div class="bikoret-shilut">
        <div class="app-title">
            <div class="admin-btn" v-if="Number(role) > 99 && state != 'manage'" @click="state='manage'">
                <p>ניהול</p>
            </div>
            <div class="admin-btn" style="background:var(--warning);" v-if="Number(role) > 99 && state == 'manage'" @click="state=null">
                <p>חזור</p>
            </div>
            <p>ביקורת שילוט</p>
        </div>

        <div class="state-manager">
            <ManageApp v-if="state=='manage'"/>
            <BikoretToBranche v-if="!state && role<=25"/>
            <CurrentBikoretActive v-if="!state && role>=99"/>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import store from '../../../store'
import ManageApp from './ManageBikoretShilut.vue';
import BikoretToBranche from './BikoretToBranche.vue'
import CurrentBikoretActive from './bikorot_management/CurrentBikoretActive.vue'

export default {
components:{ManageApp,BikoretToBranche,CurrentBikoretActive},
setup(){
    const state = ref(null)

    const user = ref(computed(() => {
        return store.state.user
    }))

    const role = ref(computed(() => {
        return store.state.role
    }))

    
    return{
        user,role, state
    }
}
}
</script>

<style scoped>
.bikoret-shilut{
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.app-title{
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 5px;
    flex-shrink: 0;
    background: var(--secondary);
    height: fit-content;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 24px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.678);
}
.admin-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    z-index: 1;
    font-size: 18px;
    background: var(--purple);
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.state-manager{
    width: 100%;
    height: calc(100% - 45px);
    overflow-y: auto;
}
</style>