<template>
  <div v-if="state && bikoret_data" class="bikoret-to-branche" v-loading="loading">
    <div class="bikoret-header">
      <h1>{{bikoret_data.title}}</h1>
    </div>
    <div class="bikoret-sms-text">
      {{bikoret_data.sms_text}}
    </div>
    <div class="bikoret-timer">
      <p class="timer-time-lbl">זמן שנותר</p>
      <p class="timer-time"><span v-if="timer.hours<10">0</span>{{timer.hours}}:<span v-if="timer.min<10">0</span>{{timer.min}}</p>
      <p class="under-time">דקות שעות</p>
      <p class="end-date">תאריך סיום: {{bikoret_data.date_end.toDate().toLocaleString('he')}}</p>
    </div>
    <div class="bikoret-images-uploads">
      <template v-for="(item) in feedback.items" :key="item.barcode">
        <div v-loading="is_panding_upload" v-if="!item.url" class="bikoret-image-uploads" @click="handle_upload_image(item)">
          <i class="material-icons camera-icon">photo_camera</i>
          <p style="font-size:25px;">{{item.item.name}}</p>
          <p>{{item.item.barcode}}</p>
        </div>
        <div v-loading="is_panding_upload" v-else class="bikoret-image-uploads">
          <img :src="item.url" />
          <div @click="handle_delete_item(item)" class="bikoret-image-uploads-remove">
              <i class="material-icons">delete</i>
          </div>
        </div>
      </template>
    </div>
    <div class="bikoret-submit">
       <el-button style="width:100%;" @click="handle_submit" type="success">שלח</el-button>
    </div>
  </div>
  <div v-if="!state" class="no-bikoret-to-branche">
    <h1>אין ביקורת להצגה או שאינך סניף</h1>
    <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fno-results_375x375.png?alt=media&token=f0d2d373-1d86-4749-9cf5-55c594b228a2">
  </div>
  <input type="file" id="upload-image" @change="handleChange"  v-show="false">

   <!-- just for mail -->
    <div class="form-email" id="form-email" v-show="false">
      <p>נשלח בשעה:{{new Date().toLocaleString('he')}}</p>
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <template  v-for="item in feedback.items" :key="item.barcode">
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{item.item.name}}
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <img :src="item.url" style="width:200px; height:200px;" />
                    </td>
                </tr>
        </template>
      </table>
    </div>
 
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import store from '../../../store'
import {projectFirestore,projectFunctions} from '../../../firebase/config'
import use_bikoret_storage from '../../../Methods/use_bikoret_storage'
import { slide_pop_error,alert } from '../../../Methods/Msgs'
import router from '../../../router'

export default {
  setup(){
    const {url,filePath,deleteImage,uploadImage}  = use_bikoret_storage()
    const file = ref(null)
    const is_panding_upload = ref(false)
    const loading = ref(false);
    const state = ref(false);
    const bikoret_data = ref(null)
    const selected_item = ref(null)

    const user = ref(computed(() => {
        return store.state.user
    }))

    const get_bikoret_data =async()=>{
      bikoret_data.value = (await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW')
      .collection('Bikorot').where('status','==','active').limit(1).get()).docs.map(doc => doc.data())[0]
      if(bikoret_data.value){
        var timer_int = setInterval(() => {
        var diff = new Date((bikoret_data.value.date_end.seconds * 1000) - Date.now())
        timer.value.hours = diff.getHours() - 2
        timer.value.min = diff.getMinutes()
        // if router change -> clear intreval!
        if(!router.currentRoute.value.path.includes("bikoretshilut")){
          clearInterval(timer_int)
        }
        }, 1000);

        feedback.value.items = bikoret_data.value.items.map(item=> ({
          item: item,
          file_path: null,
          url: null
        }))
        check_if_bikoret_is_relavant()
      }
      
    }

    const check_if_bikoret_is_relavant = ()=>{
      loading.value = true;
      if(user.value){
        if(user.value.branche){
          const index = bikoret_data.value.selected_branches.findIndex(branche=>branche.includes(user.value.branche))
          if(index!=-1){
            state.value = true
          }else{
            state.value = false
          }
        }else{
          state.value = false;
        }
        loading.value = false;
      }else{
        setTimeout(() => {
          check_if_bikoret_is_relavant();
        }, 1500);
      }
    }

    const handle_upload_image=(item)=>{
      selected_item.value = item
      document.getElementById('upload-image').click()
    }
    const handleChange=async(e)=>{
            const types = ["image/png", "image/jpeg", "image/jpg"];
             const selected = e.target.files[0];
             if (selected && types.includes(selected.type)) {
               is_panding_upload.value = true
                file.value = selected;
                await uploadImage(file.value,user.value.branche,selected_item.value.item.name);
                selected_item.value.url = url.value
                selected_item.value.file_path = filePath.value
                is_panding_upload.value = false
             }else{
                file.value = null;
                slide_pop_error('סוג קובץ זה אינו נתמך')
             }
    }
    const handle_delete_item = async (item)=>{
      is_panding_upload.value = true
      await deleteImage(item.file_path)
      item.file_path = null
      item.url = null
      is_panding_upload.value = false
    }

    const timer  = ref({
      hours:0,
      min:0
    }) 


    const feedback = ref({
      branche: null,
      user_uid:null,
      items: [],
      bikoret_uid:null,
      date: new Date()
    })

    const validatation=async()=>{
      for(const item of feedback.value.items){
        if(!item.url) return false
      }
      return true
    }
    const handle_submit = async()=>{
      if(! await validatation()){
        slide_pop_error('עליך להעלות את כלל מוצרים המבוקשים')
      }else{
        feedback.value.branche = user.value.branche
        feedback.value.user_uid = user.value.uid
        feedback.value.bikoret_uid = bikoret_data.value.uid
        await save_to_db()
        sendEmail()
        alert('success','הצלחה','הביקורת נשלחה בהצלחה')
        .then(()=>{
          router.replace('/')
        })
      }
    }

    const save_to_db = async()=>{
      await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW')
      .collection('Feedback').doc().set(feedback.value)
    }

    function sendEmail() {
        projectFunctions.httpsCallable('send_email')({
          html: document.getElementById("form-email").innerHTML,
          destinations:["yuval@rosman.co.il","sharon@rosman.co.il"],
          from:"רוסמן ביקורות",
          title:`ביקורת שילוט - ${user.value.branche}`    
        })
    }

    onMounted(async()=>{
      await get_bikoret_data()
    })

    return{
      bikoret_data,
      state,
      timer,
      loading,
      handleChange,
      feedback,
      handle_upload_image,
      is_panding_upload,
      handle_delete_item,
      handle_submit
    }
  }
}
</script>

<style scoped>
    .bikoret-to-branche{
        width: 100%;
        height: fit-content;
        color: #fff;
        background: var(--secondary);
    }
    .no-bikoret-to-branche{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
    }
    .no-bikoret-to-branche img{
      max-width: 100%;
      max-height: 100%;
    }
    .bikoret-header{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--yellow);
    }
    .bikoret-timer{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 0 5px;
      position: relative;
      margin-bottom: 10px;
      flex-shrink: 0;
    }
    .timer-time{
      width: 300px;
      background: var(--main);
      text-align: center;
      font-size: 30px;
      border-radius: 10px;
    }
    .under-time{
      font-size: 14px;
      color: grey;
    }
    .timer-time-lbl{
      color: var(--danger);
      font-size: 18px;
    }
    .end-date{
      width: 300px;
      margin: auto;
      margin-top: 5px;
      text-align: start;
    }
    .bikoret-sms-text{
      width: 100%;
      height: fit-content;
      min-height: 50px;
      background: var(--alert-purple);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px;
      margin-top: 10px;
      flex-shrink: 0;
    }
    .bikoret-images-uploads{
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns:repeat(auto-fill,minmax(300px, 1fr));
      grid-auto-rows: 250px;
      grid-gap: 5px;
      overflow: hidden;
    }
    .bikoret-image-uploads{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--alert-purple);
      height: 100%;
      width: 100%;
    }


    .bikoret-image-uploads-remove{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background: crimson;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;
    }
    .camera-icon{
      font-size: 60px;
      cursor: pointer;
    }
    
    .bikoret-image-uploads img{
      max-width: 95%;
      max-height: 95%;
    }

    .bikoret-submit{
      margin-top: 10px;
      width: 100%;
      height: fit-content;
    }


</style>