<template>
    <div class="bikorot-managment">
        <div class="bikorot-managment-header">
            <div class="search">
                <span class="p-input-icon-left" style="width:100%">
                <i class="pi pi-search" />
                <InputText
                    type="text"
                    v-model="search"
                    placeholder="חיפוש"
                    style="width:100%"
                />
            </span>
            </div>
            <div class="status-btn">
                <el-button @click="get_bikorot_by_status('active')" style="width:100%;" type="success">פעיל</el-button>
            </div>
            <div class="status-btn">
                <el-button @click="get_bikorot_by_status('close')"  style="width:100%;" type="danger">לא פעיל</el-button>
            </div>
            <div class="status-btn">
                <el-button @click="get_bikorot_by_status('all')" style="width:100%;"  type="info">הכל</el-button>
            </div>
        </div>
        <div class="bikorot-managment-content">
             <table id="records">
                <tr>
                   <th>נוצר בתאריך</th>
                   <th>מסתיים בתאריך</th>
                   <th>כותרת</th>
                   <th>סטטוס</th>
                   <th></th>
               </tr>
               <h1 style="color:#fff" v-if="bikorot.length==0">אין ביקורות להצגה </h1>
               <template v-for="bikoret in bikorot" :key="bikoret.uid">
                   <tr>
                       <td>{{bikoret.created_at.toDate().toLocaleString('he')}}</td>
                       <td>{{bikoret.date_end.toDate().toLocaleString('he')}}</td>
                       <td>{{bikoret.title}}</td>
                       <td v-if="bikoret.status=='active'" style="color:green">ביקורת פעילה</td>
                       <td v-if="bikoret.status=='close'" style="color:red">ביקורת לא פעילה</td>
                       <td><i @click="handle_click_bikoret_info(bikoret)" class="material-icons info-icon">info</i></td>
                   </tr>
                </template>
             </table>
        </div>
        <div v-if="show_selected_bikoret" class="selected-bikoret">
            <CurrentBikoretActive :bikoretId="selected_bikoret_id"/>
            <div class="close-btn" @click="show_selected_bikoret=false"><i class="material-icons back-icon">arrow_back</i></div>
        </div>
    </div>
</template>

<script>
import InputText from "primevue/inputtext";
import { ref } from '@vue/reactivity';
import {projectFirestore} from '../../../../firebase/config'
import CurrentBikoretActive from '../bikorot_management/CurrentBikoretActive.vue'
import { onMounted } from '@vue/runtime-core';
export default {
    components:{InputText,CurrentBikoretActive},
    setup(){
        const search = ref('')
        const bikorot = ref([])
        const selected_bikoret_id = ref(null)
        const show_selected_bikoret = ref(false)

        const get_bikorot_by_status=async(status)=>{
            let docs
            if(status!='all'){
                docs = await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW').collection('Bikorot')
                .where('status','==',status).get()
            }
            else{
                 docs = await projectFirestore.collection('Applications').doc('2rZeQiGNNt2eU30d3HSW').collection('Bikorot').get()
            }
            bikorot.value = docs.docs.map(doc=>doc.data())
        }

        const handle_click_bikoret_info=(bikoret)=>{
            selected_bikoret_id.value = bikoret.uid
            show_selected_bikoret.value = true
        }

        onMounted(async()=>{
            await get_bikorot_by_status('active')
        })
        return{
            handle_click_bikoret_info,
            get_bikorot_by_status,
            selected_bikoret_id,
            show_selected_bikoret,
            search,
            bikorot,
        }
    }
}
</script>

<style scoped>
    .bikorot-managment{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .selected-bikoret{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index:1;
    }
    .bikorot-managment-header{
        padding: 0 5px;
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .search{
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .status-btn{
        width:18%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;  
    }
    .bikorot-managment-content{
        width: 100%;
        height: calc(93% - 10px);
        overflow: hidden;
        overflow-y: auto;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    #records td, #records th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }
    #records tr{
        background-color: #f2f2f2;
    }
    #records tr:hover {
        background-color: #ddd;
    }
    #records th {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: var(--purple);
        color: white;
    }
    .info-icon{
        cursor: pointer;
    }
    .info-icon:hover{
        color: var(--yellow);
    }
    .close-btn{
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: crimson;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
    }
    .back-icon{
        cursor: pointer;
    }
</style>